<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        입실 날짜 선택
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:void(0)" class="util-actions util-actions-history-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="schedule-container">
        <div class="calendar-picker-container">
          <!-- calendar-picker-container > calendar-picker -->
          <div class="calendar-picker">
            <div class="picker-body body-first">
              <div class="datepicker-header">
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)">이전</button>
                <div class="title">{{ currentYearMonth }}</div>
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)">다음</button>
              </div>
              <div class="datepicker-table datepicker-table-week">
                <table>
                  <thead>
                  <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(week, idx) in currentWeeks" :key="idx">
                      <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                        <template v-if="day.month === currentMonth">
                          <button class="kb-btn-day" :class="{ 'is-active' : startDt.ymd === day.ymd }"
                                  :disabled="isPastDay(day)" @click="handleSelectDate(day)">
                            <span class="text">{{ day.day }}</span></button>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-section-wrap" v-if="startDt && startDt.day">
        <div class="section-divider">
          <div class="divider"></div>
        </div>
        <div class="section-contents side-pd">
          <ul class="schedule-list">
            <li v-for="(item, index) in items" :key="index" class="list-item" @click="selectDateTime(item)">
              <p class="schedule-time">{{ item.time }}</p>
              <button class="reservation-btn" :class="{ 'is-active' : item.valid }" >{{ item.label }}</button>
            </li>

<!--
            <template v-for="(item,idx) in items" :key="idx">
              &lt;!&ndash; TODO:: 날짜의 시간이 같을때 뿌리기 if date === time of date &ndash;&gt;
              <li class="time-item" :class="{ 'is-active' : item.valid }">
                <div class="time"><span class="time-text">{{ item.time }}</span></div>

                <a v-if="!item.valid || typeof btnFn !== 'function'" class="link">
                  <span class="link-text">{{ item.label }}</span><i class="icon-arrow"></i>
                </a>

                <a v-else href="javascript:" class="link"  @click="handleReserveBtn(item)">
                  <span class="link-text">{{ item.label }}</span><i class="icon-arrow"></i>
                </a>
              </li>
            </template>
            -->
          </ul>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, reactive, ref, onMounted} from 'vue';
import {getCalendar, getItem, lengthCheck, ymdStrToDate} from '@/assets/js/util';
import {useStore} from 'vuex';
import {ACT_GET_FCLT_RSVT_APLY, ACT_SET_FCLT_APLY_MOBILE_INFO} from '@/store/modules/trnct/trnct';
import {useRouter} from "vue-router";


export default {
  name: 'DailyMobileSportsApplyStep0Date',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isOpen = ref(false);
    const currentDate = new Date();
    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);
    // 오늘 연 월 일
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    const startDt = ref(store.state.trnct.mobileSportsApplyInfo.startDt);

    // 이번주 데이터
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));

    const currentYearMonth = computed(() => {
      return `${currentYear.value}년 ${currentMonth.value}월`;
    });

    const nextYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value + 1}월`;
      }
    });

    const prevYearMonth = computed(() => {
      if (currentMonth.value === 1) {
        return `${currentYear.value - 1}년 12월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value - 1}월`;
      }
    });

    onMounted(()=>{
      //reserveDate
      if(store.state.trnct.mobileSportsApplyInfo.reserveDate){
        currentYear.value = store.state.trnct.mobileSportsApplyInfo.reserveDate.split('-')[0];
        currentMonth.value = Number(store.state.trnct.mobileSportsApplyInfo.reserveDate.split('-')[1]);

        search();
      }


      //startDt.value = store.state.trnct.mobileApplyInfo.startDt;

    })

    const changeMonth = (isNext) => {
      if (isNext) {
        if (currentMonth.value === 12) {
          currentYear.value++;
          currentMonth.value = 1;
        } else {
          currentMonth.value++;
        }
      } else {
        if (currentMonth.value === 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }
      }
    };



    const isPastDay = (date) => {
      return currentDate >= ymdStrToDate(date.ymd);
    };

    const handleSelectDate = (date) => {
      startDt.value = date;
      searchParam.operYmd = startDt.value.ymdformat;
      search()
    };

    const searchParam = reactive({
      fcltDivCdDcd: store.state.trnct.mobileSportsApplyInfo.fcltDivCdDcd,
      operYmd: store.state.trnct.mobileSportsApplyInfo.reserveDate
    });

    // 시간정보

    const items = ref([]);
    /*const items = [
      {time: '10:00 - 12:00', valid: false},
      {time: '12:00 - 14:00', valid: true},
      {time: '14:00 - 16:00', valid: false},
      {time: '16:00 - 18:00', valid: false},
      {time: '18:00 - 20:00', valid: true}
    ];*/


    const search = async () => {

      items.value = [];

      await store.dispatch(`trnct/${ACT_GET_FCLT_RSVT_APLY}`, searchParam).then(res => {

        if (lengthCheck(res)) {
          let item = getItem(res);

          if(item.part01OperYn == 'R'){
            items.value.push({time: '08:00 - 10:00', valid: false, label: "미정",value:1});
          }else if(item.part01OperYn == 'N'){
            items.value.push({time: '08:00 - 10:00', valid: false, label: "미운영",value:1});
          }else if(item.part01OperYn == 'Y' && item.part01Cnt > 0){
            items.value.push({time: '08:00 - 10:00', valid: false, label: "예약완료",value:1});
          }else{
            items.value.push({time: '08:00 - 10:00', valid: true, label: "신청가능",value:1});
          }

          if(item.part02OperYn == 'R'){
            items.value.push({time: '10:00 - 12:00', valid: false, label: "미정",value:2});
          }else if(item.part02OperYn == 'N'){
            items.value.push({time: '10:00 - 12:00', valid: false, label: "미운영",value:2});
          }else if(item.part02OperYn == 'Y' && item.part02Cnt > 0){
            items.value.push({time: '10:00 - 12:00', valid: false, label: "예약완료",value:2});
          }else{
            items.value.push({time: '10:00 - 12:00', valid: true, label: "신청가능",value:2});
          }

          if(item.part03OperYn == 'R'){
            items.value.push({time: '12:00 - 14:00', valid: false, label: "미정",value:3});
          }else if(item.part03OperYn == 'N'){
            items.value.push({time: '12:00 - 14:00', valid: false, label: "미운영",value:3});
          }else if(item.part03OperYn == 'Y' && item.part03Cnt > 0){
            items.value.push({time: '12:00 - 14:00', valid: false, label: "예약완료",value:3});
          }else{
            items.value.push({time: '12:00 - 14:00', valid: true, label: "신청가능",value:3});
          }

          if(item.part04OperYn == 'R'){
            items.value.push({time: '14:00 - 16:00', valid: false, label: "미정",value:4});
          }else if(item.part04OperYn == 'N'){
            items.value.push({time: '14:00 - 16:00', valid: false, label: "미운영",value:4});
          }else if(item.part04OperYn == 'Y' && item.part04Cnt > 0){
            items.value.push({time: '14:00 - 16:00', valid: false, label: "예약완료",value:4});
          }else{
            items.value.push({time: '14:00 - 16:00', valid: true, label: "신청가능",value:4});
          }

          if(item.part05OperYn == 'R'){
            items.value.push({time: '16:00 - 18:00', valid: false, label: "미정",value:5});
          }else if(item.part05OperYn == 'N'){
            items.value.push({time: '16:00 - 18:00', valid: false, label: "미운영",value:5});
          }else if(item.part05OperYn == 'Y' && item.part05Cnt > 0){
            items.value.push({time: '16:00 - 18:00', valid: false, label: "예약완료",value:5});
          }else{
            items.value.push({time: '16:00 - 18:00', valid: true, label: "신청가능",value:5});
          }

        }else{
          items.value.push({time: '08:00 - 10:00', valid: false, label: "미정",value:1});
          items.value.push({time: '10:00 - 12:00', valid: false, label: "미정",value:2});
          items.value.push({time: '12:00 - 14:00', valid: false, label: "미정",value:3});
          items.value.push({time: '14:00 - 16:00', valid: false, label: "미정",value:4});
          items.value.push({time: '16:00 - 18:00', valid: false, label: "미정",value:5});
        }
      }).catch((e) => {
        console.error(e);
      })
    }

    const selectDateTime = (item) => {
      console.log(item);
      let part01RsvtYn = 'N';
      let part02RsvtYn = 'N';
      let part03RsvtYn = 'N';
      let part04RsvtYn = 'N';
      let part05RsvtYn = 'N';

      if(item.value == 1){
        part01RsvtYn= 'Y';
      }else if(item.value == 2){
        part02RsvtYn= 'Y';
      }else if(item.value == 3){
        part03RsvtYn= 'Y';
      }else if(item.value == 4){
        part04RsvtYn= 'Y';
      }else if(item.value == 5){
        part05RsvtYn= 'Y';
      }

      store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`,
          {  reserveDateTime: item, reserveDate: searchParam.operYmd, startDt: startDt.value, aplyYmd: startDt.value.ymdformat,
                    part01RsvtYn: part01RsvtYn, part02RsvtYn: part02RsvtYn, part03RsvtYn: part03RsvtYn, part04RsvtYn: part04RsvtYn, part05RsvtYn: part05RsvtYn }
      );

      router.go(-1);
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      isOpen,
      today,
      currentYear,
      currentMonth,
      currentWeeks,
      currentYearMonth,
      nextYearMonth,
      prevYearMonth,
      changeMonth,
      startDt,
      isPastDay,
      handleSelectDate,
      items,
      search,
      selectDateTime,
      goBack
    };
  },
};
</script>
